import React from 'react';
import PageContent from './pagecontent';

const Page = props => (
  <section>
    <PageContent {...props} />
  </section>
);

export default Page;
