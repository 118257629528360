import posed, { PoseGroup } from 'react-pose';
import { smoothSpring } from '../springs';
import React from 'react';
import PropTypes from 'prop-types';

const PoseSlide = posed.div({
  enter: {
    opacity: 1,
    x: 0,
    transition: smoothSpring
  },
  leftSide: {
    opacity: 0,
    x: '-100%',
    transition: smoothSpring,
    backgroundColor: '#fff'
  },
  rightSide: {
    opacity: 0,

    x: '100%',
    transition: smoothSpring,
    backgroundColor: '#fff'
  },
  flip: { transition: smoothSpring }
});

export const AnimatedSlider = ({ hook, children, ...props }) => {
  const { step, previousStep } = hook();

  const childCount = React.Children.count(children);

  let child = null;
  if (childCount === 1 && step === 0) {
    child = children;
  } else if (childCount > 1 && step < childCount) {
    child = children[step];
  }

  return (
    <PoseGroup
      preEnterPose={step > previousStep ? 'rightSide' : 'leftSide'}
      exitPose={step > previousStep ? 'leftSide' : 'rightSide'}
    >
      <PoseSlide key={step} {...props} withParent={false}>
        {child}
      </PoseSlide>
    </PoseGroup>
  );
};

AnimatedSlider.propTypes = {
  hook: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired
};
