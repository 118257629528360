import React from 'react';
import Nav from './react/components/nav';
import Footer from './react/components/footer';
import Portfolio from './react/components/portfolio';
import { BrowserRouter as Router } from 'react-router-dom';
import Page from './react/components/page';
import pageContent from './react/data/pages';
import { useLocation } from 'react-router';
import { AnimatedSlider } from './react/components/animations/slider';

const useSlider = () => {
  const {pathname} = useLocation();

  const routes = {
    '/': 0,
    '/projectHam': 1,
    '/projectSlay': 2
  };

  // default route in case pathname points to a page we don't have
  let step = routes['/'];

  if (pathname in routes) {
   step = routes[pathname]
  }

  return {
    step,
    previousStep: step === 0 ? Infinity : -Infinity
  };
};

function App() {
  return (
    <Router>
      <Nav />
      <AnimatedSlider hook={useSlider}>
        <Portfolio />
        <Page {...pageContent.MobileRecruitingpp} />
        <Page {...pageContent.projectSlay} />
      </AnimatedSlider>
      <Footer />
    </Router>
  );
}

export default App;
