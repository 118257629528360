import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

const PageContent = ({
  projectTitle,
  projectPara,
  projectHeaderImage,
  position,
  userPersona,
  lowfi,
  hifi,
  mood,
  mockup,
  wireframe,
  tools
}) => (
  <section className={styles.projectContainer}>
    <div className={styles.role}>
      <div>
        <h1>{projectTitle}</h1>
      </div>
      <div>
        <p>Role: {position}</p>
      </div>
    </div>
    <div className={classnames(styles.image)}>
      <img src={projectHeaderImage} />
    </div>
    <div className={classnames(styles.goals)}>Goals:</div>
    <div className={styles.paragraph}>{projectPara}</div>
    <div className={styles.tool}>
      <h1>Tools Used:</h1>
      <div className={styles.toolimg}>
        {tools.map(tool => (
          <img src={tool} key={tool}/>
        ))}
      </div>
    </div>
    <div className={classnames(styles.image, styles.bottomSpace)}>
      <div className={styles.subHead}>User Personas</div>
      <img src={userPersona} />
    </div>
    <div className={classnames(styles.image, styles.bottomSpace)}>
      <div className={styles.subHead}>Lo-Fi Mock & Wireframing</div>
      <img src={lowfi} />
    </div>
    <div className={classnames(styles.image, styles.bottomSpace)}>
      <div className={styles.subHead}>Mood Board</div>
      <img src={mood} />
    </div>
    <div className={classnames(styles.image, styles.bottomSpace)}>
      <div className={styles.subHead}>Hi-Fi Mock & UI</div>
      <img src={hifi} />
    </div>
  </section>
);

export default PageContent;
