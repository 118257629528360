import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import _ from 'lodash';
import Cards from './cards';

//Image
import pro from './assets/pro365.png';
import proWeb from '../page/assets/proWeb/ProWeb.png';

const cards = _.shuffle([
  { title: 'Pro365 App', bgimage: pro, link: '/projectHam' },
  { title: 'Pro Website', bgimage: proWeb, link: '/projectSlay' }
]);

function Portfolio() {
  return (
    <section className={styles.portfolio}>
      {cards.map(props => (
        <Cards {...props} key={props.title} />
      ))}
    </section>
  );
}

export default Portfolio;
