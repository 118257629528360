import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

function Index() {
  return (
    <section className={styles.footer}>
      <div></div>
    </section>
  );
}

export default Index;
