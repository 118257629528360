//Global Images
import sketch from '../components/page/assets/sketch.png';
import flinto from '../components/page/assets/flintologo.png';
import principle from '../components/page/assets/principlelogo.png';
import overflow from '../components/page/assets/overflowlogo.png';
import illustrator from '../components/page/assets/ai.png';
import photoshop from '../components/page/assets/photoshop.png';
import simple from '../components/page/assets/Simplemind.png';
import jira from '../components/page/assets/jira.svg';

//Pro Images
import pro365 from '../components/portfolio/assets/pro365.png';
import Manage from '../components/page/assets/Screen Shot 2019-12-11 at 12.57.20 PM.png';
import proWire from '../components/page/assets/pro/ProWireframe.png';
import userJim from '../components/page/assets/pro/PersonaJim.png';
import message from '../components/page/assets/pro/Messages.png';
import proMood from '../components/page/assets/pro/Moodboard.png';

//Pro Website
import proWeb from '../components/page/assets/proWeb/ProWeb.png';
import proWebLo from '../components/page/assets/proWeb/lowfilPro.png';
import proWebHi from '../components/page/assets/proWeb/ProWebFeatures.png';
import proWebPersona from '../components/page/assets/proWeb/proWebPersona.png';

const pageContent = {
  MobileRecruitingpp: {
    projectHeaderImage: pro365,
    projectTitle: 'Pro365 App',
    tools: [
      simple,
      sketch,
      flinto,
      principle,
      overflow,
      illustrator,
      photoshop,
      jira
    ],
    projectPara:
      'We set out to build a mobile recruitment app for both employers and job seekers in Michigan. ' +
      'Our goal was to develop a product that help small to medium business find the right candidates' +
      ' using skill base matching and direct messaging. Candidates can also upload a Pre-screen video ' +
      'using pre-determined questions allowing employers to put a face to the resume. We wanted to make it ' +
      'as easy as possible for a candidate to create this video because we know how hard it is to record yourself.',
    position: 'UX | UI',
    userPersona: userJim,
    lowfi: proWire,
    hifi: message,
    mood: proMood,
    mockup: Manage
  },

  projectSlay: {
    projectHeaderImage: proWeb,
    projectTitle: 'Pro365 Website',
    tools: [
      simple,
      sketch,
      flinto,
      principle,
      overflow,
      illustrator,
      photoshop,
      jira
    ],
    projectPara:
      'We set out to build a mobile recruitment app for both employers and job seekers in Michigan. ' +
      'Our goal was to develop a product that help small to medium business find the right candidates' +
      ' using skill base matching and direct messaging. Candidates can also upload a Pre-screen video ' +
      'using pre-determined questions allowing employers to put a face to the resume. We wanted to make it ' +
      'as easy as possible for a candidate to create this video because we know how hard it is to record yourself.',
    position: 'UX | UI',
    userPersona: proWebPersona,
    lowfi: proWebLo,
    hifi: proWebHi,
    mood: proMood,
    mockup: Manage
  }
};

export default pageContent;
