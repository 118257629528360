import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import Resume from './assets/NicholasLovettResume.pdf';

//images
import nick from './assets/test.png';

function Nav() {
  return (
    <section className={styles.nav}>
      <div className={styles.navContainer}>
        <div className={styles.menu}>
          <div>
            <h1>Nicholas Lovett</h1>
            <p>UX | UI | Developer</p>
          </div>
          <div>
            <button onClick={() => window.open(Resume)}>Resume</button>
          </div>
        </div>
        {/*<div className={styles.about}>*/}
        {/*  <div>*/}
        {/*    <p className={styles.info}>*/}
        {/*      Senior UX/UI designer with a development background. Strong*/}
        {/*      knowledge of UX IA procedures and wireframing. Strong design*/}
        {/*      background with great attention to detail. Familiar with re-usable*/}
        {/*      component design as well as coding re-usable components.*/}
        {/*      Professional knowledge of html, css, sass, less, scss, react, js.*/}
        {/*    </p>*/}
        {/*  </div>*/}

        {/*  <div>*/}
        {/*    <img src={nick} />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </section>
  );
}

export default Nav;
