import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { useHistory } from 'react-router';

const Cards = ({ title, bgimage, link }) => {
  const history = useHistory();

  return (
    <div className={styles.card}>
      <img src={bgimage} />
      <div className={styles.overlay}></div>
      <div className={styles.control}>
        <div className={styles.projectTitle}>{title}</div>
        <div>
          <button onClick={() => history.push(link)}>View Project</button>
        </div>
      </div>
    </div>
  );
};

export default Cards;
